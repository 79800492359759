<template>
    <div>
        <div class="preloader"></div>

        <div id="main-wrapper">

            <top-nav/>

            <side-nav/>

            <router-view/>

            <div class="footer">
                <div class="copyright">
                    <p>Copyright © TrustEarns 2018 - {{(()=>new Date().getFullYear())()}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topNav from "../../components/dashboard/topNav";
import sideNav from "../../components/dashboard/sideNav";

export default {
    name: "Container",
    components: {
        topNav,
        sideNav
    },
    mounted(){
        const dash_scripts = require("../../assets/js/custom-dashboard");
        dash_scripts();
        if($('.preloader').length){
            $('.preloader').delay(200).fadeOut(500);
        }
        setTimeout(()=>{
            this.$store.dispatch('deposit/autoRunInterest')
        }, 2000)
    }
}
</script>

<style scoped>

</style>