<template>
    <div class="deznav">
        <div class="deznav-scroll">
            <div class="main-profile">
                <div class="image-bx">
                    <img src="../../assets/images/logo-solo.png" style="width: 40px" alt="">
                </div>
                <h5 class="name"><span class="font-w400">Hello,</span> {{ getUser.data.name }}</h5>
                <p class="email">{{getUser.data.email}}</p>
            </div>
            <ul class="metismenu" id="menu" >
                <li class="nav-label first">Dashboard</li>
                <li>
                    <router-link :to="{name: 'Dashboard'}" class="ai-icon">
                        <i class="flaticon-144-layout"></i>
                        <span class="nav-text">Dashboard</span>
                    </router-link>
                </li>
                <li>
                    <a class="has-arrow ai-icon" href="javascript:void(0)" aria-expanded="false">
                        <i class="flaticon-144-layout"></i>
                        <span class="nav-text">Deposit</span>
                    </a>
                    <ul aria-expanded="false">
                        <li>
                            <router-link :to="{name: 'Deposit'}">
                                New deposit
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'DepositList'}">
                                History
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="has-arrow ai-icon" href="javascript:void(0)" aria-expanded="false">
                        <i class="flaticon-144-layout"></i>
                        <span class="nav-text">Withdrawal</span>
                    </a>
                    <ul aria-expanded="false">
                        <li>
                            <router-link :to="{name: 'Withdraw'}">
                                Withdraw
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Withdrawals'}">
                                History
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="has-arrow ai-icon" href="javascript:void(0)" aria-expanded="false">
                        <i class="flaticon-144-layout"></i>
                        <span class="nav-text">Transfer</span>
                    </a>
                    <ul aria-expanded="false">
                        <li>
                            <router-link :to="{name: 'Transfer'}">
                                Transfer
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Transfers'}">
                                History
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link :to="{name: 'Referrals'}" class="ai-icon" aria-expanded="false">
                        <i class="flaticon-144-layout"></i>
                        <span class="nav-text">Referrals <span class="badge badge-xs badge-warning">new</span></span>
                    </router-link>
                </li>


                <template v-if="getUser.data.is_admin">
                    <li class="nav-label">Admin Panel</li>
                    <li>
                        <a class="has-arrow ai-icon" href="javascript:void(0)" aria-expanded="false">
                            <i class="flaticon-077-menu-1"></i>
                            <span class="nav-text">Admin</span>
                        </a>
                        <ul aria-expanded="false">
                            <li>
                                <router-link :to="{name: 'Users'}">
                                    Users
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'Deposits'}">
                                    Deposits
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'AllWithdrawals'}">
                                    Withdrawals
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'AllContacts'}">
                                    Contacts
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </template>

            </ul>
            <div class="copyright">
                <p><strong>TrustEarns</strong> © {{(()=>new Date().getFullYear())()}} All Rights Reserved</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "sideNav",
    computed: {
        ...mapGetters('user', ['getUser'])
    },
    mounted() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            $('#menu li a:not(.has-arrow)').on('click', ()=>{
                $('.nav-control').click();
            })
        }
    }
}
</script>

<style scoped>

</style>